import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

import { Text } from '@shared/ui/text';
import { Button } from '@shared/ui/buttons/Button';
import { useNavigate } from '@hooks/useNavigate';
import { Loader } from '@shared/ui/loader/Loader';

const DonatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isUkLanguage = useIsSpecificLanguage('uk');
  const lang = isUkLanguage ? 'ua' : 'en';
  navigate('/');
  return (
    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%', marginTop: 200 }}>
      <Loader />
    </div>
  );
  // old donate page
  return (
    <div className="container px-4 pt-6 pb-12 lg:pt-12">
      <h1 className="mb-4 text-xl md:text-2xl text-center">{t('help-platform')}</h1>
      <Text component="p" size="base" className="mb-2">
        {t('help-platform-description')}
      </Text>
      <Text component="p" size="base" className="mb-2">
        {t('help-platform-current-state')}
      </Text>
      <ul className="list-disc pl-4 mb-4">
        <li className="p-2">
          <Text size="base">{t('help-platform-goal-1')}</Text>
        </li>
        <li className="p-2">
          <Text size="base">{t('help-platform-goal-2')}</Text>
        </li>
        <li className="p-2">
          <Text size="base">{t('help-platform-goal-3')}</Text>
        </li>
      </ul>
      <div className="flex justify-center">
        <Button
          as="link-out"
          size="large"
          href={`https://easypay.ua/${lang}/partners/icc-minsocpol-ukraine`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('donate-now')}
        </Button>
      </div>
    </div>
  );
};

export default DonatePage;
